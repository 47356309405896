import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  data: null,
  error: null
};

const dataSlice = createSlice({
    name: 'delete-model',
    initialState: initialState,
    reducers: {
        initDeleteModel: () => {
            return { ...initialState }
        },
        startDeleteModel: (state) => {
            state.loading = true
        },
        successDeleteModel: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        errorDeleteModel: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
})

export const { initDeleteModel, startDeleteModel, successDeleteModel, errorDeleteModel } = dataSlice.actions

export default dataSlice.reducer