import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    id: '',
    name: '',
    email: '',
    jobRole: '',
    phone: '',
    role: '',
    profileImg: null,
    companyId: '',
    companyName: '',
    companyLogo: null,
    companyPaid: false,
    authority: []
}

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
        setUser: (state, action) => {
            state.id = action.payload.id
            state.name = action.payload.name
            state.email = action.payload.email
            state.jobRole = action.payload.jobRole
            state.phone = action.payload.phone
            state.role = action.payload.role
            state.profileImg = action?.payload?.image === undefined ? null : action?.payload?.image
            state.companyId = action?.payload?.company?.id === undefined ? '' : action.payload.company.id
            state.companyName = action?.payload?.company?.name === undefined ? '' : action.payload.company.name
            state.companyLogo = action?.payload?.company?.image === undefined ? null : action?.payload?.company?.image
            state.companyPaid = action?.payload?.company?.paid === undefined ? false : action?.payload?.company?.paid
            state.authority = [action.payload.role]
            state.language = action?.payload?.language
        },
        setUserUpdate: (state, action) => {
            state.name = action.payload.name
            state.phone = action.payload.phone
            state.jobRole = action.payload.jobRole
            state.language = action.payload.language
        },
        setProfileImg: (state, action) => {
            state.profileImg = action.payload.profileImg
        },
        setCompanyLogo: (state, action) => {
            state.companyLogo = action.payload.logoImg
        },
        setCompanyUpdate: (state, action) => {
            state.companyName = action.payload.name
        },
        setEmailTwoFactorAuthentication: (state, action) => {
            state.email = action.payload
        },
        userLoggedOut: () => initialState,
	},
})

export const { setUser, setProfileImg, setCompanyLogo, setUserUpdate, setCompanyUpdate, setEmailTwoFactorAuthentication } = userSlice.actions

export default userSlice.reducer