import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    data: null
}

const dataSlice = createSlice({
    name: 'Answer/summary',
    initialState: initialState,
    reducers: {
        initGetAnswerSummary: () => {
            return { ...initialState }
        },
        startGetAnswerSummary: (state) => {
            state.loading = true
        },
        successGetAnswerSummary: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        errorGetAnswerSummary: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
})

export const { initGetAnswerSummary, startGetAnswerSummary, successGetAnswerSummary, errorGetAnswerSummary } = dataSlice.actions

export default dataSlice.reducer