
import { combineReducers } from 'redux';
import theme from './theme/themeSlice';
import session from './auth/sessionSlice';
import auth from './auth';
import base from './base';
import customers from './customers';
import company from './company';
import projects from './projects';
import themes from './themes';
import models from './models';
import questions from './questions';
import answer from './answer';

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        session,
        auth,
        base,
        customers,
        company,
        projects,
        themes,
        models,
        questions,
        answer,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
