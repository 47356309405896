import { combineReducers } from '@reduxjs/toolkit';
import create from './create/dataSlice';
import deleteTheme from './delete/dataSlice';
import getModelById from './get-by-id/dataSlice';
import list from './list/dataSlice';
import update from './update/dataSlice';
import addQuestion from './add-question/dataSlice';
import listModelsForTheme from './list-models-for-theme/dataSlice';
import updateDashboardOrder from './update-dashboard-order/dataSlice';
import reorderQuestionsModel from './reorder-questions/dataSlice';

const reducer = combineReducers({
    create,
    delete: deleteTheme,
    getModelById,
    list,
    update,
    addQuestion,
    listModelsForTheme,
    updateDashboardOrder,
    reorderQuestionsModel
})

export default reducer