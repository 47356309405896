import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  data: null,
  error: null
};

const dataSlice = createSlice({
    name: 'add-question-to-questionnaire',
    initialState: initialState,
    reducers: {
        initAddQuestionToQuestionnaire: () => {
            return { ...initialState }
        },
        startAddQuestionToQuestionnaire: (state) => {
            state.loading = true
        },
        successAddQuestionToQuestionnaire: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        errorAddQuestionToQuestionnaire: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const { initAddQuestionToQuestionnaire, startAddQuestionToQuestionnaire, successAddQuestionToQuestionnaire, errorAddQuestionToQuestionnaire } = dataSlice.actions;

export default dataSlice.reducer;