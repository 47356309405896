/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { acceptedLanguages } from "locales";
import { useTranslation } from "react-i18next";

export const Translated = ({ children }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const userBrowserLanguage = navigator.language.split('-')[0];

    if (acceptedLanguages.includes(userBrowserLanguage)) {
      i18n.changeLanguage(userBrowserLanguage);
    }
  }, []);

  return children;
};
