import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  data: null,
  error: null
};

const dataSlice = createSlice({
    name: 'delete-project',
    initialState: initialState,
    reducers: {
        eraseDeleteProject: () => {
            return { ...initialState }
        },
        startDeleteProject: (state) => {
            state.loading = true
        },
        successDeleteProject: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        errorDeleteProject: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const { eraseAdminListProjectsStore, startDeleteProject, successDeleteProject, errorDeleteProject } = dataSlice.actions

export default dataSlice.reducer