import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { themeConfig } from "configs/theme.config";
import { languages } from "constants/language.constant";
import en from "./lang/en.json";
import es from "./lang/es.json";
import pt from "./lang/pt.json";

const resources = {
  en: { translation: en },
  pt: { translation: pt },
  es: { translation: es },
};

export const acceptedLanguages = languages.map(({ value }) => value);

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: themeConfig.locale,
  lng: themeConfig.locale,
  interpolation: {
    escapeValue: false,
  },
});

export const dateLocales = {
  pt: () => import("dayjs/locale/pt"),
  en: () => import("dayjs/locale/en"),
  es: () => import("dayjs/locale/es"),
};

export default i18n;
