import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    data: {
        id: '',
        email: '',
        name: '',
        phone: '',
        jobRole: '',
        acceptNotifications: false,
        role: '',
        topics: [],
        challenges: [],
        twoFactorAuthentication: false,
        createdAt: new Date(),
        updatedAt: new Date(),
        company: {
            _id: '',
            name: '',
            paid: false,
            createdAt: new Date(),
            updatedAt: new Date(),
        }
    },
}

const dataSlice = createSlice({
    name: 'Customer/update',
    initialState: initialState,
    reducers: {
        eraseUpdateCustomerStore: () => {
            return { ...initialState }
        },
        setUpdateCustomerLoading: (state) => {
            state.loading = true
        },
        setUpdateCustomerResult: (state, action) => {
            state.data = action.payload
            state.loading = false
        }
    }
})

export const { eraseUpdateCustomerStore, setUpdateCustomerLoading, setUpdateCustomerResult } = dataSlice.actions

export default dataSlice.reducer