import React from 'react';
import Pagination from '../Pagination';
import Select from '../Select';
import { useTranslation } from 'react-i18next';

const CompletePagination = ({ limit, page, total, onChangePagination, onChangeSelect }) => {

const { t } = useTranslation()

  const pageSizeOptions = [
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
    { value: 40, label: "40" },
    { value: 50, label: "50" },
  ];

  const value = pageSizeOptions.filter((x) => x.value === limit);

    return (
        <div className="flex items-center justify-between mt-4">
            <Pagination
                pageSize={limit}
                currentPage={page}
                total={total}
                onChange={onChangePagination}
            />
            <div style={{ minWidth: 130 }}>
                <Select
                    placeholder={t('placeholder.select')}
                    size="sm"
                    isSearchable={false}
                    value={value}
                    options={pageSizeOptions}
                    onChange={onChangeSelect}
                />
            </div>
        </div>
    )
};

export default CompletePagination;