import { combineReducers } from 'redux';
import adminListProject from './admin-list-projects/dataSlice';
import listProjects from './list-projects/dataSlice';
import deleteProject from './delete-project/dataSlice';
import adminCloneProject from './admin-clone-project/dataSlice';
import cloneProject from './clone-project/dataSlice';

const reducer = combineReducers({
    adminListProject,
    adminCloneProject,
    listProjects,
    deleteProject,
    cloneProject
})

export default reducer