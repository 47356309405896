import React, { useState } from 'react';
import Input from "../Input";
import useDebounce from 'utils/hooks/useDebouce';

const SearchDebounce = ({ value, onChange, ...props }) => {
    const [displayValue, setDisplayValue] = useState(value);

    const debounceChange = useDebounce(onChange, 500);

    const handleChange = (event) => {
        const value = event.target.value;
        setDisplayValue(value);
        debounceChange(value);
    };

    return <Input name="search" value={displayValue} onChange={handleChange} {...props} />
};

export default SearchDebounce;