import { combineReducers } from '@reduxjs/toolkit';
import create from './create-theme/dataSlice';
import deleteTheme from './delete-theme/dataSlice';
import getThemeById from './get-theme-by-id/dataSlice';
import list from './list-themes/dataSlice';
import update from './update-theme/dataSlice';
import uploadThemeImage from './upload-theme-image/dataSlice';
import order from './order-theme/dataSlice';
import status from './status-theme/dataSlice';
import addModelToTheme from './add-model-to-theme/dataSlice';

const reducer = combineReducers({
    create,
    delete: deleteTheme,
    getThemeById,
    list,
    update,
    uploadThemeImage,
    order,
    status,
    addModelToTheme
})

export default reducer