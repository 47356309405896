export const areaOptions = [
  {
    value: "Comunicação Interna",
    label: "area.options.internal.communication",
  },
  {
    value: "Comunicação Externa",
    label: "area.options.external.communication",
  },
  { 
    value: "Recursos Humanos", 
    label: "area.options.human.resources"
  },
  { 
    value: "Marketing", 
    label: "area.options.marketing"
  },
];

export const challengesOptions = [
  {
    value: "Mensurar os resultados da área",
    label: "challenges.options.measure.the.results.of.the.area",
  },
  {
    value: "Saber o que os colaboradores pensam sobre a empresa",
    label: "challenges.options.find.out.what.employees.think.about.the.company",
  },
  {
    value: "Ter clareza do impacto do meu trabalho",
    label: "challenges.options.be.clear.about.the.impact.of.my.work",
  },
  {
    value: "Gerar dados para orientar minhas decisões",
    label: "challenges.options.generate.data.to.guide.my.decisions",
  },
  {
    value: "Entender o melhor jeito de comunicar aos colaboradores",
    label: "challenges.options.understand.the.best.way.to.communicate.to.employees",
  },
  {
    value: "Melhorar performance utilizando dados",
    label: "challenges.options.improve.performance.using.data",
  },
  {
    value: "Identificar os pontos de melhoria prioritários",
    label: "challenges.options.identify.priority.points.for.improvement",
  },
  {
    value: "Monitorar o desempenho da área",
    label: "tchallenges.options.monitor.area.performance",
  },
  {
    value: "Estruturar os indicadores da área",
    label: "challenges.options.structure.the.areas.indicators",
  },
  {
    value: "Repensar os indicadores da área",
    label: "challenges.options.rethink.the.areas.indicators",
  },
];

export const topicsOptions = [
  {
    label: "topics.options.communication.channels",
    value: "Canais de Comunicação",
    disabled: false,
  },
  {
    label: "topics.options.event.assessment",
    value: "Avaliação de Evento",
    disabled: false,
  },
  {
    label: "topics.options.media.usage.behavior",
    value: "Comportamento de Uso de Mídia",
    disabled: false,
  },
  {
    label: "topics.options.workplace.safety",
    value: "Segurança do Trabalho",
    disabled: false,
  },
  {
    label: "topics.options.leadership.communication",
    value: "Comunicação da Liderança",
    disabled: false,
  },
  {
    label: "Clima Organizacional",
    value: "Clima Organizacional",
    disabled: false,
  },
  {
    label: "topics.options.strategy.communication",
    value: "Comunicação da Estratégia",
    disabled: false,
  },
  { 
    label: "topics.options.diversity", 
    value: "Diversidade", 
    disabled: false 
  },
  { 
    label: "topics.options.culture", 
    value: "Cultura", 
    disabled: false 
  },
];

export const jobRoleOptions = [
  { 
    value: "Estagiário(a)", 
    label: "job.role.options.intern"
  },
  { 
    value: "Assistente", 
    label: "job.role.options.assistant"
  },
  { 
    value: "Analista", 
    label: "job.role.options.analyst"
  },
  { 
    value: "Especialista", 
    label: "job.role.options.specialist"
  },
  { 
    value: "Supervisor(a)", 
    label: "job.role.options.supervisor"
  },
  { 
    value: "Coordenador(a)", 
    label: "job.role.options.coordinator"
  },
  { 
    value: "Gerente", 
    label: "job.role.options.manager"
  },
  { 
    value: "Diretor(a)/C-level", 
    label: "job.role.options.director/c-level"
  },
  { 
    value: "Outro", 
    label: "job.role.options.other"
  },
];

export const activityOptions = [
  { 
    value: "Indústria", 
    label: "activity.options.industry"
  },
  {
    value: "Comércio (Varejo e Atacado)",
    label: "activity.options.commerce.retail.and.wholesale",
  },
  { 
    value: "Serviços", 
    label: "activity.options.services"
  },
  { 
    value: "Outro", 
    label: "activity.options.other"
  },
];

export const articleOptions = [
  { 
    value: "a", 
    label: "a" 
  },
  { 
    value: "o", 
    label: "o" 
  },
];

export const articleOptionsSpanish = [
  {
    value: 'la',
    label: 'la'
  }, 
  {
    value: 'el',
    label: 'el'
  }
]

export const acceptNotificationsOptions = [
  { 
    value: true, 
    label: "accept.notification.options.yes"
  },
  { 
    value: false, 
    label: "accept.notification.options.no"
  },
];

export const roleOptions = [
  { 
    value: "Admin", 
    label: "role.options.administrator"
  },
  { 
    value: "Customer", 
    label: "role.options.client"
  },
];

export const twoFactorAuthenticationOptions = [
  { 
    value: true, 
    label: "two.factor.authentication.options.on"
  },
  { 
    value: false, 
    label: "two.factor.authentication.options.off"
  },
];

export const paidOptions = [
  { 
    value: true, 
    label: "paid.options.client" 
  },
  { 
    value: false, 
    label: "paid.options.no.client" 
  },
];

export const topicsSegment = [
  {
    label: "topic.segment.campaign.evaluation",
    value: "Avaliação de Campanha",
    disabled: false,
  },

  {
    label: "topic.segment.event.assessment",
    value: "Avaliação de Evento",
    disabled: false,
  },
  {
    label: "topic.segment.communication.channels",
    value: "Canais de Comunicação",
    disabled: false,
  },
  {
    label: "topic.segment.organizacional.climate",
    value: "Clima Organizacional",
    disabled: false,
  },
  {
    label: "topic.segment.media.usage.behavior",
    value: "Comportamento de Uso de Mídia",
    disabled: false,
  },
  {
    label: "topic.segment.strategy.communication",
    value: "Comunicação da Estratégia",
    disabled: false,
  },
  {
    label: "topic.segment.leadership.communication",
    value: "Comunicação da Liderança",
    disabled: false,
  },
  { 
    label: "topic.segment.culture", 
    value: "Cultura", 
    disabled: false 
  },
  { 
    label: "topic.segment.diversity", 
    value: "Diversidade", 
    disabled: false 
  },
  { 
    label: "topic.segment.esg", 
    value: "ESG", 
    disabled: false 
  },
  {
    label: "topic.segment.employee.experience",
    value: "Experiência do Colaborador",
    disabled: false,
  },
  {
    label: "topic.segment.employer.brand",
    value: "Marca Empregadora",
    disabled: false,
  },
  {
    label: "topic.segment.compensation.and.benefits",
    value: "Remuneração e Benefícios",
    disabled: false,
  },
  {
    label: "topic.segment.workplace.safety",
    value: "Segurança do Trabalho",
    disabled: false,
  },
];

export const languageConstant = {
  PORTUGUESE: 'pt',
  ENGLISH: 'en',
  SPANISH: 'es'
}

export const USER_LANGUAGE = 'user-language';

export const RESPONDENT_LANGUAGE = 'respondent-language';