import { combineReducers } from 'redux';
import create from './create/dataSlice';
import deleteQuestion from './delete/dataSlice';
import update from './update/dataSlice';
import list from './list/dataSlice';
import download from './download/dataSlice';
import deleteOneQuestion from './delete-one-question/dataSlice';
import getById from './question-by-id/dataSlice';
import addQuestionToQuestionnaire from './add-question-to-questionnaire/dataSlice';

const reducer = combineReducers({
    create,
    delete: deleteQuestion,
    deleteOneQuestion: deleteOneQuestion,
    update,
    list,
    download,
    getById,
    addQuestionToQuestionnaire
})

export default reducer