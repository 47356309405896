import React, { useEffect, useState } from 'react';
import { IconButton } from '@material-tailwind/react';
import Table from '../Table';
import Button from '../Buttons';
import Checkbox from '../Checkbox';
import Switcher from '../Switcher';
import CompletePagination from '../CompletePagination';
import Spinner from '../Spinner';

const FullTable = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [compact, setCompact] = useState(false);

  const {
    columns,
    data,
    loading,
    onRedirect,
    limit,
    page,
    total,
    onChangeSelect,
    onChangePagination,
    checkeds,
    handleSelected,
  } = props;

  const [selected, setSelected] = useState([]);

  const { Tr, Th, Td, THead, TBody } = Table;

  const handleLoading = () => {
    // const totalRows = limit;

    // const arrayRows = []

    // for(let index = 0; index < totalRows; index++){
    //     arrayRows.push(`${index}`)
    // }

    // return arrayRows.map((item) => (
    //     <Tr key={item}>
    //         {columns.map((column) => (
    //             <Td key={column.key}>
    //                 <Skeleton height={35}/>
    //             </Td>
    //         ))}
    //     </Tr>
    // ));

    return (
      <div className="w-full min-h-[300px] flex justify-center items-center">
        <Spinner size={50} />
      </div>
    );
  };

  const handleChangeCheckbox = (value, data) => {
    const updated = [...selected];

    if (value) {
      if (!updated.includes(data.id)) {
        updated.push(data.id);
      }
    } else {
      const index = updated.indexOf(data.id);
      if (index !== -1) {
        updated.splice(index, 1);
      }
    }

    setSelected(updated);

    if (handleSelected) {
      handleSelected(updated);
    }
  };

  const TDStyle = { cursor: 'pointer', textAlign: 'center' };

  useEffect(() => {
    if (checkeds) {
      setSelected(checkeds);
    }
  }, [checkeds]);

  return (
    <>
      <Table compact={compact}>
        <THead>
          <Tr>
            {columns.map((column) => (
              <Th style={TDStyle} key={column.key}>
                {column.name}
              </Th>
            ))}
          </Tr>
        </THead>
        <TBody>
          {!loading &&
            (data || []).map((data) => {
              return (
                <Tr key={data.id}>
                  {columns.map(
                    (
                      {
                        button,
                        iconButton,
                        buttonName,
                        disableButton,
                        icon: Icon,
                        key,
                        variant,
                        onClick,
                        textAlign,
                        switcher,
                        onChangeSwitcher,
                        disabledSwitch,
                        checkbox,
                      },
                      index
                    ) => {
                      const handleRedirect = () => {
                        onRedirect(data.id);
                      };

                      const handleClick = () => {
                        onClick(data.id);
                      };

                      const handleSwitcher = (value) => {
                        onChangeSwitcher(data.id, value);
                      };

                      if (button) {
                        return (
                          <Td style={TDStyle} onClick={handleClick} key={index}>
                            <Button
                              variant={variant}
                              key={index}
                              children={buttonName}
                              icon={<Icon />}
                              size="sm"
                            />
                          </Td>
                        );
                      }

                      if (iconButton) {
                        return (
                          <Td
                            style={TDStyle}
                            onClick={handleClick}
                            key={`${index}-${key}`}
                          >
                            <IconButton variant="text" disabled={disableButton}>
                              <Icon size={21} />
                            </IconButton>
                          </Td>
                        );
                      }

                      if (switcher) {
                        return (
                          <Td style={TDStyle} key={`${index}-${key}`}>
                            <Switcher
                              checked={data[key]}
                              onChange={handleSwitcher}
                              disabled={disabledSwitch}
                            />
                          </Td>
                        );
                      }

                      if (checkbox) {
                        const check = selected.find((item) => item === data.id);

                        return (
                          <Td style={TDStyle} key={`${index}-${key}`}>
                            <Checkbox
                              checked={check ? true : false}
                              onChange={(value) =>
                                handleChangeCheckbox(value, data)
                              }
                            />
                          </Td>
                        );
                      }

                      return (
                        <Td
                          style={{
                            ...TDStyle,
                            textAlign: textAlign ? textAlign : 'center',
                          }}
                          onClick={handleRedirect}
                          key={index}
                        >
                          {data[key]}
                        </Td>
                      );
                    }
                  )}
                </Tr>
              );
            })}
        </TBody>
      </Table>

      {loading && handleLoading()}

      {!loading && (
        <CompletePagination
          page={page}
          total={total}
          limit={limit}
          onChangeSelect={onChangeSelect}
          onChangePagination={onChangePagination}
        />
      )}
    </>
  );
};

export default FullTable;
