import IndicafixSurveyApiService from './IndicafixSurveyApiService'

export async function apiSignIn (data) {
    return IndicafixSurveyApiService.fetchData({
        url: '/auth/signin',
        method: 'post',
        data
    })
}


export async function apiTwoFactorAuthentication(data) {
    return IndicafixSurveyApiService.fetchData({
      url: "/auth/two-factor-authentication",
      method: "post",
      data,
    });
}

export async function apiSignUp (data) {
    return IndicafixSurveyApiService.fetchData({
        url: '/auth/signup',
        method: 'post',
        data
    })
}

export async function apiSignOut () {
    return IndicafixSurveyApiService.fetchData({
        url: '/auth/signout',
        method: 'delete'
    })
}

export async function apiMe () {
    return IndicafixSurveyApiService.fetchData({
        url: '/auth/me',
        method: 'get'
    })
}

export async function apiForgotPassword (data) {
    return IndicafixSurveyApiService.fetchData({
        url: '/auth/forgot-password',
        method: 'post',
        data
    })
}

export async function apiResetPassword (data) {
    return IndicafixSurveyApiService.fetchData({
        url: '/auth/reset-password',
        method: 'post',
        data
    })
}
