import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";

function DefaultAccordion({ title, content, show }) {
  const [open, setOpen] = React.useState(show);

  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Accordion open={open}>
        <AccordionHeader
          onClick={() => handleOpen(1)}
          style={{
            border: "none",
            position: "relative",
          }}
        >
          <div className="flex items-center w-full bg-white pr-6">
            <div
              className="flex items-center absolute bg-white pr-4"
              style={{ top: "0" }}
            >
              {title}
              {open ? <HiChevronUp size={25} /> : <HiChevronDown size={25} />}
            </div>
            <span className="w-full border-solid border-[.5px] border-[#E3E3E3]" />
          </div>
        </AccordionHeader>
        <AccordionBody>{content}</AccordionBody>
      </Accordion>
    </>
  );
}

export default DefaultAccordion;
