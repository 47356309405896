import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useConfig } from '../ConfigProvider'
import { useForm } from '../Form/context'
import { useInputGroup } from '../InputGroup/context'
import useColorLevel from '../hooks/useColorLevel'
import { CONTROL_SIZES, SIZES } from '../utils/constant'
import Spinner from '../Spinner'

const Button =  React.forwardRef((props, ref) => {
	const { children, size, color, shape, variant, block, icon, endIcon, className, disabled, loading, active, danger, ...rest } = props
	const { themeColor, controlSize, primaryColorLevel } = useConfig()
	const formControlSize = useForm()?.size
	const inputGroupSize = useInputGroup()?.size
	const defaultClass = 'button'
	const sizeIconClass = 'inline-flex items-center justify-center'
	
	const splitedColor = color.split('-')

	const buttonSize = size || inputGroupSize || formControlSize || controlSize
	const buttonColor = splitedColor[0] || themeColor
	const buttonColorLevel = splitedColor[1] || primaryColorLevel

	const [increaseLevel, decreaseLevel] = useColorLevel(buttonColorLevel)

	const getButtonSize = () => {
		let sizeClass = ''
		switch (buttonSize) {
			case SIZES.LG:
				sizeClass = classNames(
					`h-${CONTROL_SIZES.lg}`,
					((icon || endIcon) && !children) ? `w-${CONTROL_SIZES.lg} ${sizeIconClass} text-2xl` : 'px-8 py-2 text-base'
				)
				break
			case SIZES.SM:
				sizeClass = classNames(
					`h-${CONTROL_SIZES.sm}`,
					((icon || endIcon) && !children) ? `w-${CONTROL_SIZES.sm} ${sizeIconClass} text-lg` : 'px-3 py-2 text-sm'
				)
				break
			case SIZES.XS:
				sizeClass = classNames(
					`h-${CONTROL_SIZES.xs}`,
					((icon || endIcon) && !children) ? `w-${CONTROL_SIZES.xs} ${sizeIconClass} text-base` : 'px-3 py-1 text-xs'
				)
				break
			default:
				sizeClass = classNames(
					`h-${CONTROL_SIZES.md}`,
					((icon || endIcon) && !children) ? `w-${CONTROL_SIZES.md} ${sizeIconClass} text-xl` : 'px-8 py-2'
				)
				break
		}
		return sizeClass
	}

	const disabledClass= 'opacity-50 cursor-not-allowed'

	const solidColor = () => {
		const btn = {
			bgColor: active ? `bg-${buttonColor}-${increaseLevel}` : `bg-${buttonColor}-${buttonColorLevel}`,
			textColor: 'text-white',
			hoverColor: active ? '' : `hover:bg-${buttonColor}-${decreaseLevel}`,
			activeColor: `active:bg-${buttonColor}-${increaseLevel}`
		}
		return getBtnColor(btn)
	}

	const twoToneColor = () => {
		const btn = {
			bgColor: active ? `bg-${buttonColor}-200 dark:bg-${buttonColor}-50` : `bg-${buttonColor}-50 dark:bg-${buttonColor}-500 dark:bg-opacity-20`,
			textColor: `text-${buttonColor}-${buttonColorLevel} dark:text-${buttonColor}-50`,
			hoverColor: active ? '' : `hover:bg-${buttonColor}-100 dark:hover:bg-${buttonColor}-500 dark:hover:bg-opacity-30`,
			activeColor: `active:bg-${buttonColor}-200 dark:active:bg-${buttonColor}-500 dark:active:bg-opacity-40`
		}
		return getBtnColor(btn)
	}

	const defaultColor = () => {
		const btn = {
			bgColor: active ? `bg-gray-100 border border-gray-300 dark:bg-gray-500 dark:border-gray-500` : `bg-white border border-gray-300 dark:bg-gray-700 dark:border-gray-700`,
			textColor: `text-gray-600 dark:text-gray-100`,
			hoverColor: active ? '' : `hover:bg-gray-50 dark:hover:bg-gray-600`,
			activeColor: `active:bg-gray-100 dark:active:bg-gray-500 dark:active:border-gray-500`
		}
		return getBtnColor(btn)
	}

	const plainColor = () => {
		const btn = {
			bgColor: active ? `bg-gray-100 dark:bg-gray-500` : 'bg-transparent border border-transparent',
			textColor: `text-gray-600 dark:text-gray-100`,
			hoverColor: active ? '' : `hover:bg-gray-50 dark:hover:bg-gray-600`,
			activeColor: `active:bg-gray-100 dark:active:bg-gray-500 dark:active:border-gray-500`
		}
		return getBtnColor(btn)
	}

	const dangerColor = () => {
		const btn = {
			bgColor: active ? `bg-red-100 dark:bg-red-500` : 'bg-red-100',
			textColor: `text-[#DC2626] dark:text-[#DC2626]`,
			hoverColor: active ? '' : `hover:bg-transparent hover:border border-red-400 dark:bg-red-400 dark:border-red-400`,
			activeColor: `active:bg-red-100 dark:active:bg-red-500 dark:active:border-red-500`
		}
		return getBtnColor(btn)
	}

	const greenOutlined = () => {
		const btn = {
			bgColor: active ? `bg-[#3AA69C] dark:bg-green-500` : 'bg-transparent border border-[#3AA69C] dark:bg-[#3AA69C] dark:border-[#3AA69C]',
			textColor: `text-[#247D75] dark:text-[#247D75]`,
			hoverColor: active ? '' : `hover:bg-green-50 dark:hover:bg-[#3AA69C]`,
			activeColor: `active:bg-[#3AA69C] dark:active:bg-[#3AA69C] dark:active:border-[#3AA69C]`
		}
		return getBtnColor(btn)
	}

	const greenNeutral = () => {
		const btn = {
			bgColor: active ? `bg-[#3AA69C]` : `bg-[#c4e4e1]`,
			textColor: `text-[#247D75]`,
			hoverColor:`hover:text-[#247D75] hover:bg-transparent border border-[#c4e4e1]`,
			activeColor: `active:bg-${buttonColor} dark:active:bg-${buttonColor} dark:active:border-${buttonColor}`
		}
		return getBtnColor(btn)
	}

	const alertColor = () => {
		const btn = {
			bgColor: active ? `bg-[#D97706] dark:bg-green-500 bg-opacity-20` : 'bg-[#D97706] bg-opacity-20 dark:bg-[#D97706] dark:border-[#3AA69C]',
			textColor: `text-[#D97706] dark:text-[#D97706]`,
			hoverColor: active ? '' : `hover:bg-opacity-30 dark:hover:bg-[#D97706]`,
			activeColor: `active:bg-[#D97706] dark:active:bg-[#D97706] dark:active:border-[#3AA69C]`
		}
		return getBtnColor(btn)
	}

	const getBtnColor = ({bgColor, hoverColor, activeColor, textColor}) => {
		return `${bgColor} ${disabled || loading ? disabledClass : (hoverColor + ' ' + activeColor)} ${textColor}`
	}

	const btnColor = () => {
		switch (variant) {
			case 'solid':
				return solidColor()
			case 'twoTone':
				return twoToneColor()
			case 'plain':
				return plainColor()
			case 'default':
				return defaultColor()
			case 'danger':
				return dangerColor()
			case 'greenOutlined':
				return greenOutlined()
			case 'greenNeutral':
				return greenNeutral()
			case 'alert':
				return alertColor()
			default:
				return defaultColor()
		}
	}
	
	const classes = classNames(
		defaultClass,
		btnColor(),
		`radius-${shape}`,
		getButtonSize(),
		className,
		block ? 'w-full' : ''
	)

	const handleClick = (e) => {
		const { onClick } = props
		if (disabled || loading) {
			e.preventDefault()
			return
		}
		(onClick)?.(e)
	}

	const renderChildren = () => {

		if(loading && children) {
			return (
				<span className="flex items-center justify-center">
					<Spinner enableTheme={false} className="mr-1" />
					{children}
				</span>
			)
		}

		if(((icon || endIcon) && !children) && loading) {
			return <Spinner enableTheme={false} />
		}

		if(((icon || endIcon) && !children) && !loading) {
			return <>{icon || endIcon}</>
		}

		if(((icon || endIcon) && children) && !loading) {
			return (
			<span className="flex items-center justify-center">
				{icon && <span className="text-lg">{icon}</span>}
				<span className="ltr:ml-1 rtl:mr-1">{children}</span>
				{endIcon && <span className="text-lg ml-1">{endIcon}</span>}
			</span>
			)
		}

		return <>{children}</>

	}

	return (
		<button 
			ref={ref} 
			className={classes} 
			{...rest} 
			onClick={handleClick}
		>
			{ renderChildren()  }
		</button>
	)
})

Button.propTypes = {
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	block: PropTypes.bool,
	shape: PropTypes.oneOf(['round', 'circle', 'none']),
	className: PropTypes.string,
	size: PropTypes.oneOf([SIZES.LG, SIZES.SM, SIZES.XS, SIZES.MD]),
	color: PropTypes.string,
	variant: PropTypes.oneOf(['solid', 'twoTone', 'plain', 'default', 'danger', 'greenOutlined', 'greenNeutral', 'alert']),
	icon: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node
	]),
	endIcon: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node
	]),
	active: PropTypes.bool,
}

Button.defaultProps = {
	variant: 'default',
	shape: 'round',
	active: false,
	loading: false,
	disabled: false,
	color: ''
}

export default Button
