import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    data: null,
    error: null
};

const dataSlice = createSlice({
    name: 'admin-clone-project',
    initialState: initialState,
    reducers: {
        eraseCloneProjectStore: () => {
            return { ...initialState }
        },
        startCloneProject: (state) => {
            state.loading = true
        },
        successCloneProject: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        errorCloneProject: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
})

export const { eraseCloneProjectStore, startCloneProject, successCloneProject, errorCloneProject } = dataSlice.actions

export default dataSlice.reducer