import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: {
    email: "",
    password: "",
  },
};

const dataSlice = createSlice({
  name: "Customer/reset-password",
  initialState: initialState,
  reducers: {
    eraseResetPasswordStore: () => {
      return { ...initialState };
    },
    setResetPasswordLoading: (state) => {
      state.loading = true;
    },
    setResetPasswordResult: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
  },
});

export const {
  eraseResetPasswordStore,
  setResetPasswordLoading,
  setResetPasswordResult,
} = dataSlice.actions;

export default dataSlice.reducer;
