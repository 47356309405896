import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  data: null,
  error: null
};

const dataSlice = createSlice({
    name: 'delete-question',
    initialState: initialState,
    reducers: {
        initDeleteQuestion: () => {
            return { ...initialState }
        },
        startDeleteQuestion: (state) => {
            state.loading = true
        },
        successDeleteQuestion: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        errorDeleteQuestion: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
})

export const { initDeleteQuestion, startDeleteQuestion, successDeleteQuestion, errorDeleteQuestion } = dataSlice.actions

export default dataSlice.reducer