const __process = (typeof global !== 'undefined' ? global : window).process

const appConfig = {
    // Default App Configurations
    apiPrefix: '/api',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    twoFactorAuthentication: "/two-factor-authentication",
    tourPath: '/onboarding',
    enableMock: false,
    locale: 'pt-br',

    // Custom App Configurations and environment vars
    indicafixSurveySvcUrl: __process.env.REACT_APP_ADDRESS_SURVEY_SVC + '/v1',
    indicafixAnswerSvcUrl: __process.env.REACT_APP_ADDRESS_ANSWER_SVC + '/v1',
    indicafixFrontendHost: __process.env.REACT_APP_ADDRESS_FRONTEND_HOST,
    projectEnvironment: __process.env.REACT_APP_PROJECT_ENV
    
}

export default appConfig