import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    data: null,
    error: null
};

const dataSlice = createSlice({
    name: 'admin-clone-project',
    initialState: initialState,
    reducers: {
        eraseAdminCloneProjectStore: () => {
            return { ...initialState }
        },
        startAdminCloneProject: (state) => {
            state.loading = true
        },
        successAdminCloneProject: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        },
        errorAdminCloneProject: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
})

export const { eraseAdminCloneProjectStore, startAdminCloneProject, successAdminCloneProject, errorAdminCloneProject } = dataSlice.actions

export default dataSlice.reducer