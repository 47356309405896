import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  data: null,
  error: null
};

const dataSlice = createSlice({
    name: 'status-theme',
    initialState: initialState,
    reducers: {
        initStatusTheme: () => {
            return { ...initialState }
        },
        startStatusTheme: (state) => {
            state.loading = true
        },
        successStatusTheme: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        errorStatusTheme: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
})

export const { initStatusTheme, startStatusTheme, successStatusTheme, errorStatusTheme } = dataSlice.actions

export default dataSlice.reducer