import { combineReducers } from '@reduxjs/toolkit'
import AllCompanies from './loadCompanies/dataSlice'
import CompanyById from './loadCompanyById/dataSlice'
import DownloadCompany from './downloadCompany/dataSlice'
import adminUpdate from './adminUpdateCompany/dataSlice';

const reducer = combineReducers({
    allCompanies: AllCompanies,
    companyById: CompanyById,
    download: DownloadCompany,
    adminUpdate
})

export default reducer