import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  data: [],
  error: null
};

const dataSlice = createSlice({
    name: 'list-themes',
    initialState: initialState,
    reducers: {
        initListThemes: () => {
            return { ...initialState }
        },
        startListThemes: (state) => {
            state.loading = true
        },
        successListThemes: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        errorListThemes: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
})

export const { initListThemes, startListThemes, successListThemes, errorListThemes } = dataSlice.actions

export default dataSlice.reducer