import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  data: null,
  error: null
};

const dataSlice = createSlice({
    name: 'dget-theme-by-id',
    initialState: initialState,
    reducers: {
        initGetThemeById: () => {
            return { ...initialState }
        },
        startGetThemeById: (state) => {
            state.loading = true
        },
        successGetThemeById: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        errorGetThemeById: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
})

export const { initGetThemeById, startGetThemeById, successGetThemeById, errorGetThemeById } = dataSlice.actions

export default dataSlice.reducer