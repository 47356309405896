import { languageConstant } from "./indicafix.constants";

export const languages = [
  {
    label: "language.options.portuguese",
    value:  languageConstant.PORTUGUESE,
  },
  {
    label: "language.options.english",
    value: languageConstant.ENGLISH,
  },
  {
    label: "language.options.espanish",
    value: languageConstant.SPANISH,
  },
];
