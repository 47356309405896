import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    data: {
        name: '',
        paid: false,
        createdAt: new Date(),
        updatedAt: new Date(),
        activity: '',
        cnpj: '',
        color: '',
        numberOfEmployees: 0,
        image: '',
        id: ''
    },
    error: null
}

const dataSlice = createSlice({
    name: 'admin-update-company',
    initialState: initialState,
    reducers: {
        initAdminUpdateCompany: () => {
            return { ...initialState }
        },
        startAdminUpdateCompany: (state) => {
            state.loading = true
        },
        successAdminUpdateCompany: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        errorAdminUpdateCompany: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
})

export const { initAdminUpdateCompany, startAdminUpdateCompany, successAdminUpdateCompany, errorAdminUpdateCompany } = dataSlice.actions

export default dataSlice.reducer