import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  data: null,
  error: null
};

const dataSlice = createSlice({
    name: 'add-question-model',
    initialState: initialState,
    reducers: {
        initAddQuestionModel: () => {
            return { ...initialState }
        },
        startAddQuestionModel: (state) => {
            state.loading = true
        },
        successAddQuestionModel: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        errorAddQuestionModel: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
})

export const { initAddQuestionModel, startAddQuestionModel, successAddQuestionModel, errorAddQuestionModel } = dataSlice.actions

export default dataSlice.reducer