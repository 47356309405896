import IndicafixBaseSurveyService from './IndicafixBaseSurveyService'

const IndicafixSurveyApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            IndicafixBaseSurveyService(param).then(response => {
                resolve(response)
            }).catch(errors => {
                reject(errors)
            })
        })
    }
}

export default IndicafixSurveyApiService