/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import { acceptedLanguages } from "./locales";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { Translated } from "locales/helpers/translated";
import { PersistGate } from "redux-persist/integration/react";
import { USER_LANGUAGE, languageConstant } from "constants/indicafix.constants";
import history from "./history";
import ReactGA from "react-ga4";
import Layout from "components/layout";
import appConfig from "configs/app.config";
import Theme from "components/template/Theme";
import "./locales";

function App() {
  let locationPath = window.location.pathname;

  const { i18n: { language, changeLanguage } } = useTranslation()

  ReactGA.initialize("G-EEVF4E1HNZ", {
    testMode: appConfig.projectEnvironment !== "production",
    gaOptions: {
      debug_mode: appConfig.projectEnvironment !== "production",
    },
    gtagOptions: {
      debug_mode: appConfig.projectEnvironment !== "production",
    },
  });

  const handleDefaultLanguage = () => {
    const previousUserLanguage = localStorage.getItem(USER_LANGUAGE);
    if (previousUserLanguage && acceptedLanguages.includes(previousUserLanguage)) {
      return changeLanguage(previousUserLanguage);
    }
  };

  const handlePlatformTittle = (language) => {
    switch(language){
      case languageConstant.PORTUGUESE:
        return handleSetTabName('Indicafix - Plataforma de Pesquisa')
      case languageConstant.SPANISH:
        return handleSetTabName('Indicafix - Plataforma de investigación')
      case languageConstant.ENGLISH:
        return handleSetTabName('Indicafix - Research Platform')
      default:
        return
    }
  }
  
  const handleSetTabName = (name) => {
    document.title = name;
  }

  useEffect(() => {
    // Google Analytics
    ReactGA.send({ hitType: "pageview", page: locationPath });
  }, [locationPath]);


  useEffect(() => {
    handlePlatformTittle(language)
  },[language])

  useEffect(() => {
    setTimeout(() =>     handleDefaultLanguage(), 100)
  },[navigator, localStorage])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter history={history}>
          <Theme>
            <Translated>
              <Layout />
            </Translated>
          </Theme>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
