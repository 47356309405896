import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    data: {
        email: '',
        name: '',
        phone: '',
        jobRole: '',
        acceptNotifications: false,
        role: '',
        topics: [],
        challenges: [],
        twoFactorAuthentication: false,
        createdAt: new Date(),
        updatedAt: new Date(),
        id: '',
        company: {
            _id: '',
            name: '',
            paid: false,
            createdAt: new Date(),
            updatedAt: new Date(),
        }
    },
}

const dataSlice = createSlice({
    name: 'Customer/detail',
    initialState: initialState,
    reducers: {
        eraseCustomerStore: () => {
            return { ...initialState }
        },
        setCustomerLoading: (state) => {
            state.loading = true
        },
        setCustomerResult: (state, action) => {
            state.data = action.payload
            state.loading = false
        }
    }
})

export const { eraseCustomerStore, setCustomerLoading, setCustomerResult } = dataSlice.actions

export default dataSlice.reducer