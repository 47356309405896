import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  docs: [],
  totalDocs: 0,
  limit: 0,
  totalPages: 0,
  page: 0,
  pagingCounter: 0,
  hasPrevPage: false,
  hasNextPage: false,
  prevPage: null,
  nextPage: null,
};

const dataSlice = createSlice({
  name: "Customers/data",
  initialState: initialState,
  reducers: {
    eraseCustomersStore: () => {
      return { ...initialState };
    },
    setCustomersLoading: (state) => {
      state.loading = true;
    },
    setCustomersResults: (state, action) => {
      state.loading = false;
      state.docs = action.payload.docs;
      state.totalDocs = action.payload.totalDocs;
      state.limit = action.payload.limit;
      state.totalPages = action.payload.totalPages;
      state.page = action.payload.page;
      state.pagingCounter = action.payload.pagingCounter;
      state.hasPrevPage = action.payload.hasPrevPage;
      state.hasNextPage = action.payload.hasNextPage;
      state.prevPage = action.payload.prevPage;
      state.nextPage = action.payload.nextPage;
    },
  },
});

export const { eraseCustomersStore, setCustomersLoading, setCustomersResults } = dataSlice.actions

export default dataSlice.reducer