import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  data: null,
  error: null
};

const dataSlice = createSlice({
    name: 'delete-theme',
    initialState: initialState,
    reducers: {
        initDeleteTheme: () => {
            return { ...initialState }
        },
        startDeleteTheme: (state) => {
            state.loading = true
        },
        successDeleteTheme: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        errorDeleteTheme: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
})

export const { initDeleteTheme, startDeleteTheme, successDeleteTheme, errorDeleteTheme } = dataSlice.actions

export default dataSlice.reducer