import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState, setEmailTwoFactorAuthentication } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp, apiMe, apiForgotPassword, apiResetPassword, apiTwoFactorAuthentication } from 'services/IndicafixAuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import { useNavigate } from 'react-router-dom'
import appConfig from 'configs/app.config'


function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { token, signedIn, email } = useSelector((state) => ({
    token: state.session.token,
    signedIn: state.session.signedIn,
    email: state.auth.user.email
  }));

  const signIn = async (values) => {
        
		try {
			
			const resp = await apiSignIn(values)

			if(resp.status === 204){
				dispatch(setEmailTwoFactorAuthentication(values.email))
				navigate(appConfig.twoFactorAuthentication)
      }
			
      if (resp.status === 201 && resp.data) {
        handleUserAuthenticated(resp.data);

        return {
          status: "success",
          message: "",
        };
      }

    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  }

  const twoFactorAuthentication = async (token) => {
    try {
      const resp = await apiTwoFactorAuthentication({ token: token, email: email });

      handleUserAuthenticated(resp.data);

    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      };
    }
  };

  const handleUserAuthenticated = (data) => {
    const { accessToken } = data;
    dispatch(onSignInSuccess(accessToken));

    if (data.user) {
      data.user.authority = [data.user.role];
      dispatch(setUser(data.user));
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);

      if (resp.data) {
        const { accessToken } = resp.data;
        dispatch(onSignInSuccess(accessToken));

        if (resp.data.user) {
          resp.data.user.authority = [resp.data.user.role];
          dispatch(setUser(resp.data.user));
        }

        setTimeout(() => {
          navigate("/onboarding");
        }, 500);

        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.error || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));

    // Clear the session and user data from localStorage
    localStorage.removeItem('session');
    localStorage.removeItem('user');
    

    setTimeout(() => {
      navigate(appConfig.unAuthenticatedEntryPath);
    }, 500);
  };

  const signOut = async () => {
    try {
      await apiSignOut();
      handleSignOut();

      return {
        status: "success",
        message: "",
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const forgotPasswordCreate = async (values) => {
    try {
      await apiForgotPassword(values);

      return {
        status: "success",
        message: "",
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const forgotPasswordReset = async (values) => {
    try {
      await apiResetPassword(values);

      return {
        status: "success",
        message: "",
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const getMe = async (values) => {
    try {
      const resp = await apiMe(values);

      if (resp.data.user) {
        resp.data.user.authority = [resp.data.user.role];
        dispatch(setUser(resp.data.user));
      }

      return {
        status: "success",
        message: "",
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  return {
    authenticated: token && signedIn ? true : false,
    signIn,
    signUp,
    signOut,
    forgotPasswordCreate,
    forgotPasswordReset,
    getMe,
    twoFactorAuthentication,
  };
}

export default useAuth