import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  data: [],
  error: null
};

const dataSlice = createSlice({
    name: 'list-models',
    initialState: initialState,
    reducers: {
        initListModels: () => {
            return { ...initialState }
        },
        startListModels: (state) => {
            state.loading = true
        },
        successListModels: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        errorListModels: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
})

export const { initListModels, startListModels, successListModels, errorListModels } = dataSlice.actions

export default dataSlice.reducer