import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    data: {
        url: null
    }
}

const dataSlice = createSlice({
    name: 'Customer/download',
    initialState: initialState,
    reducers: {
        iniDownloadCustomersStore: () => {
            return { ...initialState }
        },
        startDownloadCustomers: (state) => {
            state.loading = true
        },
        sucessDownloadCustomers: (state, action) => {
            state.data = action.payload
            state.loading = false
        }
    }
})

export const { iniDownloadCustomersStore, startDownloadCustomers, sucessDownloadCustomers } = dataSlice.actions

export default dataSlice.reducer