import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    data: {
        name: '',
        paid: false,
        createdAt: new Date(),
        updatedAt: new Date(),
        activity: '',
        cnpj: '',
        color: '',
        numberOfEmployees: 0,
        image: '',
        id: ''
    },
}

const dataSlice = createSlice({
    name: 'load-company-by-id',
    initialState: initialState,
    reducers: {
        eraseCompanyByIdStore: () => {
            return { ...initialState }
        },
        setCompanyByIdLoading: (state) => {
            state.loading = true
        },
        setCompanyByIdResult: (state, action) => {
            state.data = action.payload
            state.loading = false
        }
    }
})

export const { eraseCompanyByIdStore, setCompanyByIdLoading, setCompanyByIdResult } = dataSlice.actions

export default dataSlice.reducer