import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    error: null,
    data: {
        url: null
    }
}

const dataSlice = createSlice({
    name: 'Questions/download',
    initialState: initialState,
    reducers: {
        iniDownloadQuestionsStore: () => {
            return { ...initialState }
        },
        startDownloadQuestions: (state) => {
            state.loading = true
        },
        successDownloadQuestions: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        errorDownloadQuestions: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
})

export const { iniDownloadQuestionsStore, startDownloadQuestions, successDownloadQuestions, errorDownloadQuestions } = dataSlice.actions

export default dataSlice.reducer