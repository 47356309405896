import React from 'react';
import { CompletePagination, ProjectCard, Spinner } from 'components/ui';
import { useTranslation } from 'react-i18next';

const ListProject = (props) => {

    const { t } = useTranslation()

    const { 
        projects, 
        onClick, 
        onViewProject, 
        onDeleteProject, 
        loading = true, 
        limit,
        page, 
        totalDocs, 
        onChangePagination, 
        onChangeSelect,
        onDuplicatedProject,
    } = props;

    const notProjects = !loading && (!projects || projects.length === 0)  ? true : false;

    return (
        <>
            <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-4">
                {!loading && (projects || []).map((project) => (
                    <ProjectCard
                        dropDown
                        data={project}
                        key={project.id}
                        loading={loading}
                        onClick={() => onClick(project.id)}
                        onViewProject={() => onViewProject(project.id)}
                        onDeleteProject={() => onDeleteProject(project.id)}
                        onDuplicatedProject={() => onDuplicatedProject(project.id)}
                    />
                ))}
                {loading && (
                    <div className='col-span-12 flex justify-center items-center min-h-[300px]'>
                        <Spinner size={50} />
                    </div>
                )}
            </div>

            {notProjects && (
                <div className="flex flex-col items-center justify-center pt-5 pb-10 w-full">
                    <img  className="text-xs capitalize"  src="/img/views/home/lightbulb.png" alt={``} style={{ height:150, marginBottom:25 }} />			
                    <p className='text-base font-semibold'>{t('create.your.first.project.and.explore.countless.possibilities')}</p>
                </div>
            )}

            {!loading && !notProjects && (
                <CompletePagination
                    limit={limit}
                    page={page}
                    total={totalDocs}
                    onChangePagination={onChangePagination}
                    onChangeSelect={onChangeSelect}
                />
            )}
        </>
    );
};

export default ListProject;