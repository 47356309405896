import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Tag, Dropdown } from 'components/ui'
import { ConfirmDialog, EllipsisButton } from 'components/shared';
import { fullPortugueseDateString, fullSpanishDateString } from 'utils/indicafix/dates';
import { languageConstant } from 'constants/indicafix.constants';
import { HiOutlineClock, HiOutlineDocumentDuplicate, HiOutlineEye, HiOutlineTrash } from 'react-icons/hi';
import moment from 'moment';

const ProjectCard = ({ onClick, dropDown, onDeleteProject, onViewProject, onDuplicatedProject, data, loading }) => {

    const { t, i18n: { language } } = useTranslation()

    const [open, setOpen] = useState(false);

    const [click, setClick] = useState(false);

    const onClose = () => setOpen(false);
    
    const onOpen = () => setOpen(true);

    const fetchDeleteProject = () => {
        onDeleteProject();
        setClick(true);
    };

	const fullDateString = () => {
        moment().locale(language)

        switch(language) {
            case languageConstant.ENGLISH:
                return moment(data.createdAt).format('LL');
            case languageConstant.PORTUGUESE:
                return fullPortugueseDateString(new Date(data.createdAt))
            case languageConstant.SPANISH:
                return fullSpanishDateString(new Date(data.createdAt))
            default:
                return moment(data.createdAt).format('LL');
        }
    }

    const ProjectStatusTag = (props) => {
        const {data} = props;

        if (data.migrated === true && data.lastRoundStatus === 'finished') {
            return (<Tag className="bg-indicafixgreen-300 text-indicafixgreen-600 border-0 rounded">
                {t('search.completed')}
            </Tag>)
        } else if (data.questionnaireStatus === 'pending') {
            return (<Tag className="bg-amber-100 text-amber-600 border-0 rounded">
            {t('questionnaire.under.implementation')}
        </Tag>)
        } else if (data.questionnaireStatus === 'in translation') {
            return (<Tag className="bg-[#EFF6FF] text-[#2563EB] border-0 rounded"> {t('in.translation')}</Tag>)
        } else if (data.questionnaireStatus === 'translated') {
            return (<Tag className="bg-amber-100 text-amber-600 border-0 rounded"> {t('translated')}</Tag>)
        } else if (data.questionnaireStatus === 'finished' && data.lastRoundStatus === 'pending') {
            return (<Tag className="bg-indicafixpurple-50 text-white border-0 rounded">
                {t('collection.not.started')}
            </Tag>)
        } else if (data.questionnaireStatus === 'finished' && data.lastRoundStatus === 'started') {
            return (<Tag className="bg-indicafixpurple-600 text-white border-0 rounded">
                {t('collection.in.progress')}
            </Tag>)
        } else if (data.questionnaireStatus === 'finished' && data.lastRoundStatus === 'paused') {
            return (<Tag className="bg-red-100 text-red-600 border-0 rounded">
                {t('collection.paused')}
            </Tag>)
        } else if (data.questionnaireStatus === 'finished' && data.lastRoundStatus === 'finished') {
            return (<Tag className="bg-indicafixgreen-600 text-white border-0 rounded">
                {t('available.results')}
            </Tag>)
        } else {
            return <></>
        }
    };

    useEffect(() => {
        if (click && !loading) {
            setOpen(false);
        }
    },[click, loading])

    return (
        <>        
            <Card 
                clickable
                onClick={onClick}
                bodyClass="h-full"
                className='hover:shadow-lg transition duration-150 ease-in-out'
                >
                <div className="flex flex-col justify-between h-full">
                    <div className="flex justify-between">
                        <h6>{data.name}</h6>
                        {dropDown && (
                            <Dropdown placement="bottom-end" renderTitle={<EllipsisButton />} onClick={(event) => event.stopPropagation()}>
                                {onViewProject && (
                                    <Dropdown.Item onClick={(event) => {
                                            event.stopPropagation();
                                            onViewProject();
                                        }}>
                                        <span className="text-lg">
                                            <HiOutlineEye />
                                        </span>
                                        <span>{t('view.project')}</span>
                                    </Dropdown.Item>
                                )}
                                
                                {onDuplicatedProject && (
                                    <Dropdown.Item onClick={(event) => {
                                        onDuplicatedProject();
                                        event.stopPropagation();
                                    }}>
                                        <span className="text-lg">
                                            <HiOutlineDocumentDuplicate />
                                        </span>
                                        <span onClick={onDuplicatedProject}>{t('duplicated.project')}</span>
                                    </Dropdown.Item>     
                                )}

                                {onDeleteProject && (
                                    <Dropdown.Item onClick={(event) => {
                                        onOpen();
                                        event.stopPropagation();
                                    }}>
                                        <span className="text-lg text-red-600">
                                            <HiOutlineTrash />
                                        </span>
                                        <span className='text-red-600' onClick={onOpen}>{t('delete.project')}</span>
                                    </Dropdown.Item>
                                )}
                            </Dropdown>
                        )}
                    </div>
                    <p className="mt-4">{data.description}</p>
                    <div className="mt-3">
                        <div className="flex items-center justify-between mt-2">
                            <ProjectStatusTag data={data} />
                            <div className="flex items-center rounded-full font-semibold text-xs">
                                <div className="flex items-center">
                                    <HiOutlineClock className="text-base" />
                                    <span className="ml-1 rtl:mr-1 whitespace-nowrap">{fullDateString()}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>

            {open && (
                <ConfirmDialog
                    type='danger'
                    isOpen={open}
                    loading={loading}
                    onClose={onClose}
                    onCancel={onClose}
                    cancelText={t('button.cancel')}
                    title={t('delete.project')}
                    confirmButtonColor='red'
                    onRequestClose={onClose}
                    onConfirm={fetchDeleteProject}
                    confirmText={loading ? t('deleting') : t('delete')}
                >
                    <p>{t('are.you.sure.you.would.like.to.delete.the.project')} <span className='font-bold'>{data?.name}</span> ?
                    <br/>
                    <span className='font-semibold text-red-600'>{t('this.action.will.be.irreversible')}</span></p>
                </ConfirmDialog>
            )}
        </>
    )
}

export default ProjectCard;