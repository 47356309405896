import { combineReducers } from '@reduxjs/toolkit'
import Customers from './loadCustomers/dataSlice'
import CustomerById from './loadCustomerById/dataSlice'
import CustomerUpdate from './updateCustomer/dataSlice'
import CustomersWhereCompany from './loadCustomersWhereCompany/dataSlice'
import ResetPassword from "./resetPassword/dataSlice";
import DownloadCustomer from './dowloadCustomers/dataSlice'

const reducer = combineReducers({
  allCustomers: Customers,
  customerById: CustomerById,
  update: CustomerUpdate,
  allCustomersWhereCompany: CustomersWhereCompany,
  resetPassword: ResetPassword,
  download: DownloadCustomer
});

export default reducer